import React, { createContext } from 'react';

import { useUser } from '../hooks';

const initialState = {
    user: null,
    isLoadingUser: false,
    userHasPlan: false,
    userPlan: null,
    userTier: 0,
    isSignedIn: false,
    customer: null,
    customerIsLoading: false,
    createCustomer: null,
    fetchCustomer: null,
    updateCustomer: null
};

const AppContext = createContext(initialState);

const AppProvider = ({ children }) => {
    return <AppContext.Provider value={useUser()}>{children}</AppContext.Provider>;
};
export default AppContext;

export { AppProvider };
